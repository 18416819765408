<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" :isDisabled=searchButtonDisable>
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <a-form-item label="City" name="city" class="rms-form-item">
              <a-select v-model:value="formState.city" :options="cityOps" placeholder="请选择"
                @change="selectChangeHandler('city')" :disabled="!cityOps.length || routerDisabeld || step !== 0">
              </a-select>
            </a-form-item>
            <a-form-item label="Building" name="building" class="rms-form-item">
              <a-select v-model:value="formState.building" :options="buildingOps" placeholder="请选择"
                @change="selectChangeHandler('building')" :disabled="!buildingOps.length || routerDisabeld || step !== 0">
              </a-select>
            </a-form-item>
            <a-form-item label="Floor" name="floor" class="rms-form-item">
              <a-select v-model:value="formState.floor" :options="floorOps" placeholder="请选择"
                @change="selectChangeHandler('floor')" :disabled="!floorOps.length || routerDisabeld || step !== 0">
              </a-select>
            </a-form-item>
            <a-form-item label="Date" name="Date" class="rms-form-item">
              <a-range-picker v-model:value="formState.date" format="YYYY-MM-DD" :mode="mode" :disabledDate="disabledDate"
                valueFormat="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" :allowClear="false" />
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button v-if="!actionFlag" :disabled="addFlag || (formState.floor =='' || formState.floor == undefined) " type="primary" @click="newApplicationClick">
              <PlusOutlined />新建申请
            </a-button>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted, onActivated,
} from "vue";
import { message } from 'ant-design-vue';
import { PlusOutlined } from "@ant-design/icons-vue"
import { cloneDeep } from "lodash";
import { useGlobalPropertyHook } from '@/hooks/common'
import moment from 'moment/moment'

const mode = ref(["date", "date"]);
export default defineComponent({
  name: "TopFilter",
  components: { PlusOutlined },
  emits: ["search", "refresh-table", "add-application", "get-seat-map",'other-refresh'],
  props: {
    addFlag: {
      type: Boolean,
      default: false
    },
    step:{
      type:Number
    }
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const actionFlag = ref()
    const disabledDate = (current) => {
      return current < moment().subtract(1, 'day').endOf('day');
    }
    let initData = {
      city: undefined,
      building: undefined,
      floor: undefined,
      date: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
    }
    const formState = reactive({ ...initData });
    const formRef = ref();
    const resetForm = () => {
      if (routerDisabeld.value === false && props.step === 0 && actionFlag.value === null) {
        Object.assign(formState, initData)
        formRef.value.resetFields();
        updateParams()
        // 相当于清空city
        selectChangeHandler('city')
        // 这里重置不再调用请求
        ctx.emit("refresh-table", currentState);
      } else {
        // 重置或者延期等的时候不允许改对应的city这些，只重置时间
        formState.date = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        doSearch()
      }

    };
    let currentState = {}
    const updateParams = () => {
      currentState = cloneDeep(formState);
    }
    const doSearch = () => {
      updateParams()
      if (currentState.date.length === 0) {
        message.warning('请选择时间查询');
        return false
      }
      ctx.emit("search", currentState);
    };
    const dateSearch = (date) => {
      formState.date = date
      updateParams()
      ctx.emit("search", currentState);
    }
    const newApplicationClick = () => {
      ctx.emit("add-application")
    }

    const cityOps = ref([])
    const buildingOps = ref([])
    const floorOps = ref([])

    // 下拉筛选全部List(没有进行联动)
    const originList = {}

    const queryBaseData = () => {
      $api.queryBaseData({ "types": ["city", "work_location", "floor"] }).then((res) => {
        const data = res.data
        let city = data.filter((item) => { return item.type == "city" })
        let building = data.filter((item) => { return item.type == 'work_location' })
        let floor = data.filter((item) => { return item.type == 'floor' })
        handlerSelectOption(city, building, floor)
      });
    };
    // 注意使用下列函数格式化时候，传入的顺序不能变
    const handlerSelectOption = (city, building, floor) => {
      let mapData = (data) => {
        return data.map((item) => {
          return {
            ...item,
            value: item.code,
            label: item.name
          }
        })
      }
      originList['city'] = mapData(city)
      cityOps.value = originList['city']
      originList['building'] = mapData(building)
      originList['floor'] = mapData(floor)
      // 把所有下拉关系保存一份
      sessionStorage.setItem('seatSessionSelectOption', JSON.stringify(originList))
    }
    // 联动city，building，floor
    const selectChangeHandler = (item) => {
      if (item == 'city') {
        searchButtonDisable.value = true
        if (formState[item] && formState[item].length) {
          buildingOps.value = originList.building.filter((jtem) => { return [formState[item]].includes(jtem.uplevel) })
          formState.building = undefined
        } else {
          buildingOps.value = []
          formState.building = undefined
          floorOps.value = []
          formState.floor = undefined
        }
      }
      if (item == 'building') {
        searchButtonDisable.value = true
        if (formState[item] && formState[item].length) {
          floorOps.value = originList.floor.filter((jtem) => { return [`${formState[item]}`].includes(jtem.uplevel) })
          formState.floor = undefined
        } else {
          floorOps.value = []
          formState.floor = undefined
        }
      }
    }

    let searchButtonDisable = ref(true)
    // 延期或者修改禁止更改
    let routerDisabeld = ref(false)
    watch(
      () => formState.floor,
      (val) => {
        if (val && val.length) {
          searchButtonDisable.value = false
          ctx.emit('get-seat-map', val)
        }
      },
      { immediate: true }
    )
    onMounted(() => {
      queryBaseData()
      //doSearch()
    })
    onActivated(() => {
      actionFlag.value = sessionStorage.getItem('actionFlag')
      if (!actionFlag.value) {
        routerDisabeld.value = false
        resetForm()
      } else {
        // 在activated里不需要重新请求下拉，而把缓存的拿来重新赋值
        let allOptionList = JSON.parse(sessionStorage.getItem('seatSessionSelectOption'))
        cityOps.value = allOptionList.city
        buildingOps.value = allOptionList.building
        floorOps.value = allOptionList.floor
        let seatInfoData = JSON.parse(sessionStorage.getItem('seatInfoData'))
        formState.city = seatInfoData.city
        formState.building = seatInfoData.building
        formState.floor = seatInfoData.floor
        formState.date = [seatInfoData.startDate, seatInfoData.endDate]
        routerDisabeld.value = true
        doSearch()
      }
    });
    return {
      actionFlag,
      formState,
      formRef,
      cityOps,
      buildingOps,
      floorOps,
      resetForm,
      doSearch,
      dateSearch,
      newApplicationClick,
      queryBaseData,
      mode,
      disabledDate,
      selectChangeHandler,
      searchButtonDisable,
      routerDisabeld
    };
  },
});
</script>
<style scoped lang="less">
.buttons-wrap {
  :deep(.ant-btn) {
    display: flex;
  }

  :deep(.anticon) {
    font-size: 20px;
  }
}
</style>