<template>
  <section class="app-container" ref="seatApplicationRef">
    <Filter
        ref="seatFilterRef"
        :step="step"
        :addFlag="addFlag||step!==0"
        @get-seat-map="getSeatMap"
        @search="handleSearch"
        @refresh-table="handleResetTable"
        @add-application="addApplication">
    </Filter>
    <div class="content">
      <!-- 空白时候的图片 -->
      <div class="noSeat" v-show="showNoSeat"></div>
      <!-- 空白时候的图片结束 -->
      <div class="content-l" v-show="!showNoSeat">
        <div class="left">
          <span class="icon icon_seat_blue">可用座位</span>
          <span class="icon icon_seat_green">已选座位</span>
          <span class="icon icon_seat_red">不可用座位</span>
          <span class="icon icon_seat_white_border">安全隔离座位</span>
        </div>
        <div class="right">
          您已选择了<span class="font-blue">{{seatArr.length}}</span>个座位
        </div>
        <div class="seat-box">
          <SeatMaps ref="seatMapsRef" :locationName="locationName" :selectedData="seatArr" :seatData="seatData" @get-seat-data="getSeatData"></SeatMaps>
          <div class="lastUpdateTime">最后更新日期: {{actionFlag?seatInfoData.operationTime:lastUpdateTime}}</div>
        </div>
      </div>
      <div class="content-r">
        <div v-show="step===0" class="info">
          <div class="seat-data">
            <span class="font font-red">{{ seatSum }}</span>
            <span>共有座位</span>
          </div>
          <div class="seat-data">
            <span class="font font-green">{{ usingSeatSum }}</span>
            <span>使用中座位</span>
          </div>
          <div class="seat-data">
            <span class="font font-dark-blue">{{ canUseSeatSum }}</span>
            <span>可用座位</span>
          </div>
        </div>
        <div v-show="step===0" class="seat-list">
          <div class="tt">已选座位</div>
          <ul>
            <template v-for="(item,index) in seatArr" :key="item.code">
              <li :class="item.safety==='1'?'bg-yellow':''">
                <span>座位编号：</span>
                <span class="num">{{ item.code }}</span>
                <span v-if="item.safety==='1'" class="font-yellow">（安全隔离座位）</span>
                <i class="icon-del" @click="seatSelDel(index)">
                  <DeleteOutlined />
                </i>
              </li>
            </template>
          </ul>
        </div>
        <div v-show="step===1" class="seat-step-first">
          <div class="tt">申请信息 第一步</div>
          <div class="tips">如人员暂未确定，可先暂时输入并选择TBD</div>
          <ul>
            <li v-for="(item,index) in formSeatArr" :key="item.code">
              <div class="label">
                <span class="num">座位 {{ item.code }}</span>
                <i class="icon-del" @click="seatSelDel(index)">删除</i>
              </div>
              <div class="form-item" ref="formItemRef">
                <a-select
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                    placeholder="请输入GGID编号/姓名"
                    autocomplete="off"
                    v-model:value="item.value"
                    :options="authorizedPersonOpts"
                    :filter-option="filterOption"
                    @change="(val)=>authorizedPersonChange(val,item)"
                    @select="(val,opt)=>authorizedPersonSelect(val,opt,item)"
                    showSearch
                    allowClear
                />
              </div>
              <div v-if="item.error" class="error">
                <span class="err-info">{{ item.error }}</span>
                <span v-show="item.error.indexOf('请勿重复选择使用人')===-1" class="view" @click="seatErrorView(item)">查看</span>
              </div>
            </li>
          </ul>
          <div class="btn-box">
            <a-button class="font-gray" type="primary" ghost @click="cancelClick">取消</a-button>
            <a-button class="ml12" type="primary" @click="nextStep">下一步</a-button>
          </div>
        </div>
        <div v-show="step===2" class="seat-step-second">
          <div class="tt">申请信息 第二步</div>
          <div class="form-box">
            <div class="applicant">
              <span>申请人</span>
              <span>{{applyPerson}}</span>
            </div>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :rules="formRules"
                :model="formState">
              <a-form-item
                  label="Group Lead"
                  name="groupLead"
                  class="rms-form-item">
                <a-select
                    v-model:value="formState.groupLead"
                    :options="groupLeadOpts"
                    placeholder="请选择"
                    allowClear
                    :disabled="userInfo.roles[0]==='16'||!!actionFlag"
                >
                </a-select>
              </a-form-item>
              <a-form-item label="项目组" name="engagementDescription" class="rms-form-item">
                <a-input
                    v-model:value="formState.engagementDescription"
                    :placeholder="formState.engagementDescription === null ? '' :'请输入'"
                    :maxlength="30"
                    allowClear
                    :disabled="!!actionFlag"
                />
              </a-form-item>
              <a-form-item label="使用日期" class="rms-form-item">
<!--                <a-range-picker
                    v-model:value="formState.applyDate"
                    format="YYYY-MM-DD"
                    :mode="mode"
                    :disabledDate="disabledDate"
                    valueFormat="YYYY-MM-DD"
                    :placeholder="['开始时间', '结束时间']"
                />-->
                <a-date-picker
                    class="flex-width"
                    v-model:value="formState.startDate"
                    :disabled-date="disabledStartDate"
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    placeholder="开始日期"
                    @openChange="handleStartOpenChange"
                    :allowClear="false"
                    :disabled="actionFlag==='2'"
                />
                -
                <a-date-picker
                    class="flex-width"
                    v-model:value="formState.endDate"
                    :disabled-date="disabledEndDate"
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    placeholder="终止日期"
                    :open="endOpen"
                    @openChange="handleEndOpenChange"
                    :allowClear="false"
                />
              </a-form-item>
              <a-form-item v-show="proveVisible" name="prove" class="rms-form-item">
                <a-button type="primary" :disabled="!!actionFlag&&seatInfoData.applicationStatus==='1'&&fileIdFlag" ghost @click="uploadProve"><ImportOutlined />上传审批证明</a-button>
                <p class="remind">*您申请的座位包含部分安全隔离区域，请上传审批凭证</p>
                <div class="file-List" v-for="(item,index) in formFileList" :key="index">
                  <FileExclamationOutlined /><span class="file-name">{{item.name}}</span>
                  <i class="icon-del" :class="!!actionFlag&&seatInfoData.applicationStatus==='1'&&fileIdFlag?'disabled':''" @click="fileDel">
                    <DeleteOutlined />
                  </i>
                </div>
              </a-form-item>
              <a-form-item label="备注" name="reason" class="rms-form-item">
                <a-textarea
                    allowClear
                    v-model:value="formState.reason"
                    :auto-size="{ minRows: 4, maxRows: 4 }"
                    showCount
                    :maxlength="500"
                />
              </a-form-item>
            </a-form>
          </div>
          <div class="btn-box">
            <a-button class="font-gray" type="primary" ghost @click="cancelClick">取消</a-button>
            <a-button class="ml12" type="primary" @click="backStep">上一步</a-button>
            <a-button class="ml12" type="primary" @click="nextStep">提交</a-button>
          </div>
        </div>
      </div>
    </div>
    <a-modal
        class="demo-modal"
        width="29.2vw"
        v-model:visible="uploadProveVisible"
        title="上传审批证明"
        cancelText="取消"
        okText="确定"
        @cancel="handleCancel"
        @ok="handleUploadOk"
        centered
    >
      <div>
        <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true"
                          accept=".jpg,.jpeg,.png,.pdf,.bmp" :beforeUpload="handleBeforeUpload"
                          :remove="removeFile">
          <p class="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p class="ant-upload-text">上传文件</p>
          <div class="ant-upload-text-alert">
            <p>单次最多只允许上传1个文件，单个文件大小不能超过50M</p>
            <p>仅支持上传jpg、jpeg、png、pdf、bmp格式文件</p>
          </div>
        </a-upload-dragger>
      </div>
    </a-modal>
    <ModalBox :getContainer="()=>$refs.seatApplicationRef" v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
    <a-modal
        :getContainer="()=>$refs.seatApplicationRef"
        class="seat-info"
        width="30vw"
        :title="'座位编号：'+seatInfoNum"
        v-model:visible="infoVisible"
        :footer="null"
        centered>
      <ul class="seat-info-list">
        <li v-for="(item,index) in seatInfoList" :key="index">
          <div class="status" :class="item.status==='1'?'bg-green':'bg-blue'">{{item.status==='1'?'已通过':'待审批'}}</div>
          <div class="desc">
            <span class="label">申请人：</span>
            <span class="contents"><i>{{item.applicantName}}</i>({{item.applicantEmail}})</span>
          </div>
          <div class="desc">
            <span class="label">Group lead：</span>
            <span class="contents"><i>{{item.groupLeadName}}</i>({{item.groupLeadEmail}})</span>
          </div>
          <div class="desc">
            <span class="label">项目组：</span>
            <span class="contents">{{item.engagementDescription?item.engagementDescription:'--'}}</span>
          </div>
          <div class="desc desc-half">
            <span class="label">使用人：</span>
            <span class="contents">{{item.userName}}</span>
          </div>
          <div class="desc desc-half">
            <span class="label">使用时间：</span>
            <span class="contents">{{item.startDate}}至{{item.endDate}}</span>
          </div>
        </li>
      </ul>
    </a-modal>
    <a-modal
        :getContainer="()=>$refs.seatApplicationRef"
        class="seat-repeat-info"
        width="40.625vw"
        title="重复申请单信息"
        v-model:visible="repeatInfoVisible"
        centered>
      <ul class="seat-repeat-info-list">
        <template v-for="(item,index) in seatRepeatInfoList" :key="index">
          <li>
            <div class="user-person">使用人：{{ item.userName }}</div>
            <div class="desc desc-half">
              <span class="label">申请单号：</span>
              <span class="contents">{{item.id}}</span>
            </div>
            <div class="desc desc-half">
              <span class="label">使用时间：</span>
              <span class="contents">{{item.startDate}}至{{item.endDate}}</span>
            </div>
            <div class="desc">
              <span class="label">申请状态：</span>
<!--              已通过，待审批， 待操作-->
              <span class="contents">{{item.status==='1'?'已通过':'待审批'}}</span>
            </div>
            <div class="desc">
              <span class="label">申请人：</span>
              <span class="contents"><i>{{item.applicantName}}</i>({{item.applicantEmail}})</span>
            </div>
            <div class="desc">
              <span class="label">Group lead：</span>
              <span class="contents"><i>{{item.groupLeadName}}</i>({{item.groupLeadEmail}})</span>
            </div>
            <div class="desc">
              <span class="label">申请信息：</span>
              <span class="contents">{{item.engagementDescription?item.engagementDescription:'--'}}</span>
            </div>
          </li>
        </template>
      </ul>
      <template #footer>
        <a-button key="back" @click="closeModal">返回</a-button>
      </template>
    </a-modal>
  </section>
</template>
<script>
import moment from 'moment/moment'
import {ref, reactive, defineComponent, watch, onMounted, onActivated, nextTick} from "vue"
import {message} from 'ant-design-vue'
//import {debounce} from "lodash";
import {DeleteOutlined,UploadOutlined,ImportOutlined,FileExclamationOutlined} from '@ant-design/icons-vue'
import { useGlobalPropertyHook } from "@/hooks/common"
import Filter from "./components/Filter.vue";
import SeatMaps from "../components/SeatMaps.vue"
import ModalBox from '@/components/ModalBox'
import { useRouter,useRoute } from "vue-router";
import {useStore} from 'vuex'
import {onBeforeRouteLeave} from 'vue-router/dist/vue-router'
export default defineComponent({
  name: "seatApplication",
  components: {
    Filter,
    SeatMaps,
    DeleteOutlined,
    UploadOutlined,
    ImportOutlined,
    FileExclamationOutlined,
    ModalBox
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const actionFlag = ref('');//'1是修改，2是延期'
    const seatInfoData = ref();
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    if(route.query && route.query.groupLead){
      nextTick(() => {
        formState.groupLead = route.query.groupLead
      })
    }
    const userInfo = store.state.app.user
    const mode = ref(["date", "date"]);
    /*let validateRangePicker = async (rule, value) => {
      if (value.length===0) {
        return Promise.reject('请选择座位使用日期');
      } else {
        return Promise.resolve();
      }
    };*/
    const formRules = {
      groupLead: [
        {
          required: true,
          message: "请选择Group Lead信息！",
          trigger: "change",
          type: 'string',
        },
      ],
/*      applyDate: [
        {
          required: true,
          trigger: "blur",
          type: 'array',
          validator: validateRangePicker,
        },
      ],*/
    };
    //const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const applyPerson = ref()
    applyPerson.value = `${userInfo.fullName}（${userInfo.email}）`
    const seatFilterRef = ref()
    const addFlag = ref(false)
    const seatArr = ref([])
    const formSeatArr = ref()
    const step = ref(0)
    /*const disabledDate = (current) => {
      return current < moment().subtract(1, 'day').endOf('day');
    }*/
    const formRef = ref()
    let initData = {
      groupLead: undefined,
      engagementDescription: '',
      //applyDate: [],
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      reason: '',
    }
    const formState = reactive({...initData})
    const seatMapsRef = ref()
    const locationName = ref()
    const getSeatMap = (data) => {
      showNoSeat.value = false
      nextTick(() => {
        seatFilterRef.value.doSearch()
        locationName.value = data
      });
    }
    const filterParams = ref()
    const endOpen = ref(false);
    const disabledStartDate = startValue => {
      if (!startValue || !formState.endDate) {
        return false;
      }
      return startValue.valueOf() > moment(formState.endDate).valueOf() || startValue.valueOf() < moment(moment().format("YYYY-MM-DD")).valueOf()
    };
    const disabledEndDate = endValue => {
      if (!endValue || !formState.startDate) {
        return false;
      }
      return moment(formState.startDate).valueOf() > endValue.valueOf();
    };
    const handleStartOpenChange = open => {
      if (!open) {
        endOpen.value = true;
      }
    };
    const handleEndOpenChange = open => {
      endOpen.value = open;
    };
    const handleSearch = (searchParams) => {
      addFlag.value=false
      if(step.value === 0){
        seatArr.value = []
        formSeatArr.value = []
      }
      filterParams.value = searchParams
      formState.startDate = searchParams.date[0]
      formState.endDate = searchParams.date[1]
      getSeatUsageInfo(searchParams)

    }
    const handleResetTable = (searchParams) => {
      if(step.value === 0){
        addFlag.value=false
        seatArr.value = []
        formSeatArr.value = []
      }
      seatSum.value = '-'
      usingSeatSum.value = '-'
      canUseSeatSum.value = '-'
      showNoSeat.value = true
      filterParams.value = searchParams
      formState.startDate = searchParams.date[0]
      formState.endDate = searchParams.date[1]
      if(searchParams.floor){
        getSeatUsageInfo(searchParams)
      }
    }
    const addApplication = () => {
      if(proveVisible.value){
        content.value = '您位置中包含安全隔离区域座位，将需要上传相关审批文件。是否继续申请？'
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 2
        modalData.value = ''
        modelValue.value = true
      }else{
        addFlag.value=true
        step.value=1
        formSeatArr.value= [...seatArr.value]
      }
    }
    //座位信息弹窗
    const infoVisible = ref(false)
    const seatInfoNum = ref()
    const seatInfoList = ref([])

    //重复申请单信息弹窗
    const repeatInfoVisible = ref(false)
    const getSeatData = (e,data) => {
      if(step.value !== 2){
        if(data.status === '1'){
          querySeatInfo(data)
        }else{
          if(!seatArr.value.find(item=>item.name===data.name)){
            if(!actionFlag.value){
              let newArr1 = [...seatArr.value]
              let newArr2 = [...formSeatArr.value]
              if(data.safety==='1'){
                if(newArr1.findIndex(x=>x.safety==='0')>-1){
                  newArr1.splice(newArr1.findIndex(x=>x.safety==='0'),0,{...data})
                  newArr2.splice(newArr2.findIndex(x=>x.safety==='0'),0,{...data})
                }else{
                  newArr1.push({...data})
                  newArr2.push({...data})
                }
              }else{
                newArr1.push({...data})
                newArr2.push({...data})
              }
              seatArr.value = [...newArr1]
              formSeatArr.value = [...newArr2]
            }else if(!!actionFlag.value&&seatInfoData.value.seatApplicationUserCommandList.find(item=>item.seatName===data.name)){
              if(data.safety==='1'){
                seatArr.value.splice(seatArr.value.findIndex(x=>x.safety==='0'),0,{...data})
                formSeatArr.value.splice(formSeatArr.value.findIndex(x=>x.safety==='0'),0,{...data})
              }else{
                seatArr.value.push({...data})
                formSeatArr.value.push({...data})
              }
              /*formSeatArr.value = seatArr.value.map(item=>{
                //if(formSeatArr.value.find(item.name))
                return{
                  error: '',
                  value: '',
                  ...item
                }
              })*/
            }
          }else {
            seatArr.value.filter((item,index)=>{
              if(item.name === data.name){
                seatSelDel(index)
              }
            })
          }
        }
      }
    }
    const seatSelDel = (index) => {
      if(actionFlag.value){
        delModal(index)
      }else{
        delData(index)
      }
    }
    const delModal = (index) => {
      content.value = '删除后，该座位将被取消使用，是否确认删除？'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 3
      modalData.value = index
      modelValue.value = true
    }
    const delData = (index) => {
      //如果座位被占用，则座位变红
      if(flag.value === 3 && formSeatArr.value[index].seatList?.length>0){
        let seatIndex = seatData.value.map(item => item.code).indexOf(formSeatArr.value[index].code)
        seatData.value[seatIndex].status = '1'
      }
      seatArr.value.splice(index,1)
      formSeatArr.value.splice(index,1)
      formSeatArr.value.forEach((item)=>{
        if(item.value){
          authorizedPersonSelect(item.value,item.data,item)
        }
      })
    }
    const seatErrorView = (data) =>{
      repeatInfoVisible.value = true
      queryRepeatSeatApplication(data)
    }
    const closeModal = ()=>{
      repeatInfoVisible.value = false
    }
    const seatRepeatInfoList = ref([])
    const queryRepeatSeatApplication = (data) =>{
      seatRepeatInfoList.value = []
      const params = {
        id: actionFlag.value?seatInfoData.value.id:'',
        userId: data.data?data.data.id:'TBD',
        code: data.code,
        startDate:filterParams.value.date[0],
        endDate: filterParams.value.date[1],
      }
      $api.queryRepeatSeatApplication(params).then((res) => {
        const {userList,seatList} = res.data
        seatRepeatInfoList.value = [...userList,...seatList]
      });
    }
    const cancelClick = () => {
      if(actionFlag.value==='1'){
        router.push({
          path: '/my/application',
          query: {
            id:seatInfoData.value.id,
            flag: sessionStorage.getItem('isDetailFlag')   //返回列表
          }
        })
      }else if(actionFlag.value==='2'){
        router.push({
          path: '/my/application',
          query: {
            id:seatInfoData.value.id,
            flag: 0   //延期返回列表
          }
        })
      }else{
        formFileList.value = []
        formRef.value.resetFields();
        step.value = 0
      }
    }
    const backStep = () => {
      formRef.value.clearValidate();
      step.value -= 1
    }
    const nextStep = () => {
      if(step.value===2){
        if(actionFlag.value&&formSeatArr.value.length<seatInfoData.value.seatApplicationUserCommandList.length){
          content.value = '删除的座位将被取消使用，是否继续？'
          flag.value = 4
          modelValue.value = true
          return false
        }
        formRef.value.validate().then(()=>{
          if(proveVisible.value&&formFileList.value.length===0){
            message.warning("您申请的座位包含安全隔离区域，请上传审批凭证！");
            return false
          }
          seatApplication()
        })
      }else{
        if(formSeatArr.value.length === 0){
          message.warning('请至少选择一个座位！')
          return false
        }else if(formSeatArr.value?.find(item=>!item.value)){
          message.warning('请完整填写申请信息！')
          return false
        }else if(formSeatArr.value?.find(item=>item.error&&item.error !== '')){
          message.warning('所选时间段内包含不可用座位，请重新选择！')
          return false
        }
        userInfo.roles[0]==='16' && !['1','2'].includes(actionFlag.value) ?formState.groupLead=userInfo.id:''
        step.value += 1
      }
    }

    const uploadProveVisible = ref(false)
    const uploadProve = () => {
      fileList.value = [...formFileList.value]
      uploadProveVisible.value = true;
    }
    const handleCancel = () => {
      fileList.value = []
      uploadProveVisible.value = false;
    }
    const handleUploadOk = () => {
      formFileList.value = [...fileList.value]
      uploadFiles();
      uploadProveVisible.value = false;
    }
    const fileId = ref()
    //修改延期  是否有上传审批文件（判断普通已坐人转安全隔离座位）
    const fileIdFlag = ref(true)
    const uploadFiles = () => {
      const formData = new FormData();
      fileList.value.forEach((item) => {
        formData.append("file", item);
      });
      $api.seatUpload(formData).then(res => {
        if (res.code == 200) {
          fileId.value = res.data[0].id
        }
      })
    }
    const removeFile = (file) => {
      fuc(file)
      return false
    }
    const fuc = (file) => {
      content.value = '确定删除这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 1
      modalData.value = {file}
      modelValue.value = true
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref()
    const modalData = ref()
    const handleOk = () => {
      if(flag.value === 1){
        const {file} = modalData.value
        const index = fileList.value.indexOf(file)
        fileList.value.splice(index, 1)
      }else if(flag.value === 2){
        addFlag.value=true
        step.value=1
        formSeatArr.value= [...seatArr.value]
      }else if(flag.value === 3){
        delData(modalData.value)
      }else if(flag.value === 4){
        formRef.value.validate().then(()=>{
          if(proveVisible.value&&formFileList.value.length===0){
            message.warning("您申请的座位包含安全隔离区域，请上传审批凭证！");
            return false
          }
          seatApplication()
        })
      }
    }
    //弹窗fileList
    const fileList = ref([])
    //表单formFileList
    const formFileList = ref([])
    const fileDel = () =>{
      if(!!actionFlag.value&&seatInfoData.value.applicationStatus==='1'){
        return false
      }
      formFileList.value = []
    }
    let messageTimer = null;
    const handleBeforeUpload = (file, list) => {
      return new Promise(() => {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i]) {
            if (fileList.value[i].name === file.name) {
              // settimeout 防止弹出多个消息提示
              clearTimeout(messageTimer);
              messageTimer = setTimeout(() => {
                message.error("请不要选择重复的文件！");
              });
              return false;
            }
          }
        }
        const num = 1
        const info = "最多上传一个附件"
        if (list.length > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }
        if (fileList.value.length + 1 > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }

        if((file.name.split('.').length - 1) > 1){
          message.error("文件名不能包含下列任何字符：. \\ / * ? < > |！");
          return false;
        }
        if (file.size === 0) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件为空文件，请检查后再次上传!");
          });
          return false;
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件大小限制50MB，请检查后再次上传!");
          });
          return false;
        }
        if (!file.type.includes("image/jpeg") &&
            !file.type.includes("image/png") &&
            !file.type.includes("image/bmp") &&
            !file.type.includes("application/pdf")
        ) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("只可以上传jpg、jpeg、png、pdf、bmp格式的文件！");
          });
          return false;
        }
        fileList.value = [...fileList.value, file];
      })
    };

    const lastUpdateTime = ref()
    const seatSum = ref('-')
    const usingSeatSum = ref('-')
    const canUseSeatSum = ref('-')
    const seatData = ref([])
    const getSeatUsageInfo = (data) => {
      const params = {
        "uplevel": data.floor,
        "startDate": data.date[0],
        "endDate": data.date[1]
      };
      $api.getSeatUsageInfo(params).then((res) => {
        const data = res.data
        seatSum.value = data.seatSum
        usingSeatSum.value = data.usingSeatSum
        canUseSeatSum.value = data.canUseSeatSum
        lastUpdateTime.value = data.lastUpdateTime
      });
      $api.queryUsedSeat(params).then((res) => {
        const data = res.data
        seatData.value = [...data]
        if(actionFlag.value){
          //seatInfoData.value = JSON.parse(sessionStorage.getItem('seatInfoData'))
          //获取所有座位的状态
          seatArr.value = seatInfoData.value.seatApplicationUserCommandList.map(item=>{
            return {
              code: item.seatCode,
              name: item.seatName,
              safety: item.isSafety,
              status: '0',
              error: '',
              value: '',
            }
          })
          //获取 要修改的座位的 数据
          formSeatArr.value = seatArr.value.map((item,index)=>{
            let x = seatInfoData.value.seatApplicationUserCommandList[index]
            return{
              ...item,
              data: {
                email: x.userEmail,
                full_name: x.userFullName,
                ggid: x.ggid,
                id: x.userId,
                value: x.userFullName+(x.userId==='TBD'?'':'（'+x.userEmail+'）'),
              },
              error: '',
              value: x.userFullName+(x.userId==='TBD'?'':'（'+x.userEmail+'）')
            }
          })
          //过滤 所有座位   把要修改的座位 转换成可选状态
          seatData.value = seatData.value.map((item)=>{
            if(seatArr.value.find(x=>item.code===x.code)){
              return {
                ...item,
                status:'0'
              }
            }else{
              return {...item}
            }
          })
          //选中 要修改的座位
          seatArr.value.forEach(item=>{
            seatMapsRef.value.checkSeat(item.name)
          })
          formState.groupLead = seatInfoData.value.groupLead
          formState.engagementDescription = seatInfoData.value.engagementDescription
          formState.startDate = filterParams.value.date[0]
          formState.endDate = filterParams.value.date[1]
          formState.reason = seatInfoData.value.reason
          formFileList.value = seatInfoData.value.fileId?[{
            name: seatInfoData.value.fileName
          }]:[]
          fileId.value = seatInfoData.value.fileId
          fileIdFlag.value = seatInfoData.value.fileId?true:false
        }
        if(step.value === 1){
            formSeatArr.value.forEach((item)=>{
            if(item.value){
              authorizedPersonSelect(item.value,item.data,item)
            }
            })
          }
      });
    };
    const querySeatInfo = (data) => {
      const {code} = data
      const params = {
        "code": code,
        "startDate": filterParams.value.date[0],
        "endDate": filterParams.value.date[1]
      };
      $api.querySeatInfo(params).then((res) => {
        const data = res.data
        seatInfoNum.value = code
        seatInfoList.value = data
        infoVisible.value = true
      });
    }
    const authorizedPersonOpts = ref()
    const authorizedPerson = ()=>{
      $api.authorizedPerson().then((res) => {
        authorizedPersonOpts.value = res.data.map(item=>{
          return {
            ...item,
            value: item.full_name+'（'+item.email+'）'
          }
        })
        authorizedPersonOpts.value.unshift({
          email: '',
          full_name: 'TBD',
          ggid: '',
          id: 'TBD',
          value: 'TBD'
        })
      });
    }
    const filterOption = (input, option) => {
      if (!input) return false;
      //去掉所有标点符号和空格
      //let reg = /[\s|\~|`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g
      let reg = /[\s|/,|/，]*/g
      return option.value.replace(reg,'').toUpperCase().indexOf(input.replace(reg,'').toUpperCase()) >= 0||option.ggid.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };
    const authorizedPersonChange = (val,data)=>{
      if(!val){
        /*data.data=''
        data.error=''
        data.value=''*/
        formSeatArr.value.filter((item)=>{
          if(item.code===data.code){
            item.error = ''
            item.data = ''
            item.value=undefined
          }
        })
        if(step.value === 1){
          formSeatArr.value.forEach((item)=>{
            if(item.value){
              authorizedPersonSelect(item.value,item.data,item)
            }
          })
        }
        /*formSeatArr.value.forEach((items,index)=>{
          if(items.error==='*请勿重复选择使用人'){
            if (!formSeatArr.value.find((item,i)=>item.data?.id===items.data?.id&&i!==index)){
              items.error = ''
            }
          }
        })*/
      }
    }
    let showNoSeat = ref(true)
    const authorizedPersonSelect = (val,opt,data) => {
      formSeatArr.value.filter((item)=>{
        if(item.name===data.name){
          item.error = ''
          item.data = opt
          item.value = val
        }
      })
      if(val){
        const params = {
          id: actionFlag.value?seatInfoData.value.id:'',
          userId: opt.id,
          code: data.code,
          startDate:filterParams.value.date[0],
          endDate: filterParams.value.date[1],
        }
        $api.checkUserSeatApplication(params).then((res) => {
          formSeatArr.value.filter((item)=>{
            if(item.code===data.code){
              item.error = (res.msg !== '操作成功'?res.msg:'')
              item.data = opt
            }
          })
          let newArr = [...formSeatArr.value]
          formSeatArr.value.forEach((items,index)=>{
            if(newArr.find((item,i)=>item.data?.id===items.data?.id&&index!==i)&&items.data?.id!=='TBD'&&items.value){
              formSeatArr.value[index].error = '*请勿重复选择使用人'
            }
          })
          //用来判断座位是否被占用
          formSeatArr.value[formSeatArr.value.map(item => item.code).indexOf(params.code)].seatList = res.data? res.data.seatList : []
        });
      }
    }
    const groupLeadOpts = ref([])
    const queryGroupLead = () => {
      $api.queryGroupLead().then((res) => {
        const data = res.data
        groupLeadOpts.value = data.map(item=>{
          return{
            ...item,
            value: item.id,
            label: `${item.full_name}（${item.email}）`,
          }
        })
      });
    }
    const seatApplication = ()=>{
      const params = {
        ...formState,
        startDate: formState.startDate,
        endDate: formState.endDate,
        fileId: proveVisible.value?fileId.value:'',
        seatApplicationUserList: formSeatArr.value.map(item=>{
          return {
            userId: item.data?item.data.id:'TBD',
            seatCode: item.code,
          }
        }),
        id: actionFlag.value?seatInfoData.value.id:''
      };
      $api.seatApplication(params).then((res) => {
        const {code,data,msg} = res.data
        if(code === 200){
          message.success(msg)
          if(actionFlag.value==='1'){
            router.push({
              path: '/my/application',
              query: {
                id:seatInfoData.value.id,
                flag: sessionStorage.getItem('isDetailFlag')   //返回列表
              }
            })
          }else if(actionFlag.value==='2') {
            router.push({
              path: '/my/application',
              query: {
                id: seatInfoData.value.id,
                flag: 0   //返回详情
              }
            })
          }
          sessionStorage.removeItem('actionFlag','')
          sessionStorage.removeItem('isDetailFlag','')
          sessionStorage.removeItem('seatInfoData','')
          step.value = 0
          seatArr.value = []
          formSeatArr.value = []
          formFileList.value = []
          Object.assign(formState,initData)
          seatFilterRef.value.resetForm()
        }else{
          message.error(msg)
          step.value = 1
          seatFilterRef.value.dateSearch([formState.startDate,formState.endDate])
          formSeatArr.value.filter((items)=>{
            if(data.userList?.find(item=>items.code===item.code)){
              items.error = msg
            }
          })
          formSeatArr.value.filter((items)=>{
            if(data.seatList?.find(item=>items.code===item.code)&&!data.userList?.find(item=>items.code===item.code)){
              items.error = msg
            }
          })
        }
      });
    }
    watch(
        ()=>step.value,
        (val)=>{
          val===0?addFlag.value=false:''
        },
    )
    const proveVisible = ref(false)
    watch(
        ()=>formSeatArr.value,
        (val)=>{
          val&&val?.find(item=>item.safety === '1')?proveVisible.value = true:proveVisible.value = false
        },
        {
          immediate:true,
          deep: true
        }
    )
    onMounted(()=>{

    })
    onActivated(()=>{
      Object.assign(formState,initData)
      actionFlag.value = sessionStorage.getItem('actionFlag')
      seatInfoData.value = JSON.parse(sessionStorage.getItem('seatInfoData'))
      if(actionFlag.value === '1'){
        step.value = 1
      }else if(actionFlag.value === '2'){
        step.value = 2
      }else{
        step.value = 0
        seatArr.value = []
        formSeatArr.value = []
        formFileList.value = []
      }
      setTimeout(()=>{
        authorizedPerson()
        queryGroupLead()
      },500)
    })
    onBeforeRouteLeave(() => {
      sessionStorage.removeItem('actionFlag','')
      sessionStorage.removeItem('isDetailFlag','')
      sessionStorage.removeItem('seatInfoData','')
      step.value = 0
      seatArr.value = []
      formSeatArr.value = []
      formFileList.value = []
      Object.assign(formState,initData)
      seatFilterRef.value.resetForm()
    });
    return {
      actionFlag,
      seatInfoData,
      userInfo,
      applyPerson,
      mode,
      //disabledDate,
      formRef,
      formRules,
      seatFilterRef,
      addFlag,
      step,
      seatArr,
      formSeatArr,
      proveVisible,
      fileId,
      fileIdFlag,
      groupLeadOpts,
      formState,
      seatMapsRef,
      locationName,
      filterParams,
      seatData,
      getSeatMap,
      handleSearch,
      handleResetTable,
      addApplication,
      seatInfoNum,
      seatInfoList,
      infoVisible,
      repeatInfoVisible,
      seatRepeatInfoList,
      getSeatData,
      seatSelDel,
      delModal,
      seatErrorView,
      closeModal,
      cancelClick,
      backStep,
      nextStep,
      fileList,
      formFileList,
      fileDel,
      uploadProveVisible,
      uploadProve,
      handleCancel,
      handleUploadOk,
      handleBeforeUpload,
      removeFile,
      modelValue,
      content,
      showIconCom,
      handleOk,
      getSeatUsageInfo,
      filterOption,
      authorizedPerson,
      authorizedPersonChange,
      authorizedPersonSelect,
      authorizedPersonOpts,
      lastUpdateTime,
      seatSum,
      usingSeatSum,
      canUseSeatSum,
      endOpen,
      disabledStartDate,
      disabledEndDate,
      handleStartOpenChange,
      handleEndOpenChange,
      showNoSeat
    };
  },
});
</script>
<style scoped lang="less">
.app-container{
  background: #F7FAFC;
}
.content{
  display: flex;
  gap: 25px;
  width: 100%;
  height: calc(100% - 90px);
  .noSeat {
    flex: 1;
    position: relative;
    right:0.3vw;
    background-image: url("../../../../assets/images/noSeat.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .content-l{
    flex: auto;
    padding: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    background: #fff;
    .left{
      display: inline-block;
      .icon{
        position: relative;
        display: inline-block;
        margin-right: 10px;
        padding-left: 32px;
        line-height: 24px;
        font-weight: 500;
        &:before{
          position: absolute;
          display: block;
          top: 2px;
          left: 0;
          content: '';
          width: 20px;
          height: 20px;
        }
        &.icon_seat_blue{
          &:before{
            background: url("../../../../assets/images/icon_seat_blue.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_green{
          &:before{
            background: url("../../../../assets/images/icon_seat_green.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_red{
          &:before{
            background: url("../../../../assets/images/icon_seat_red.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_green_border{
          &:before{
            background: url("../../../../assets/images/icon_seat_green_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_red_border{
          &:before{
            background: url("../../../../assets/images/icon_seat_red_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_white_border{
          &:before{
            background: url("../../../../assets/images/icon_seat_white_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .right{
      float: right;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      .font-blue{
        padding: 0 4px;
        color: #1890FF;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .seat-box{
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: calc(100% - 34px);
      .lastUpdateTime{
        position: absolute;
        bottom: 16px;
        left: 16px;
        padding: 0 16px;
        height: 38px;
        line-height: 38px;
        border-radius: 4px;
        font-size: 16px;
        background: rgba(245, 245, 245, 0.79);
      }
    }
  }
  .content-r{
    width: 460px;
    .info{
      display: flex;
      gap: 8px;
      width: 100%;
      .seat-data{
        flex: 1;
        height: 130px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
        background: #fff;
        span{
          display: block;
          width: 100%;
          line-height: 22px;
          text-align: center;
          font-size: 16px;
          color: #999;
          &.font{
            margin-top: 20px;
            line-height: 68px;
            font-size: 48px;
            font-weight: 600;
          }
          &.font-red{
            color: #58B99E;
          }
          &.font-green{
            color: #DC5E4F;
          }
          &.font-dark-blue{
            color: #3182CE;
          }
        }
      }
    }
    .seat-list{
      margin-top: 16px;
      width: 100%;
      height: calc(100% - 146px);
      font-size: 16px;
      border-top: 5px solid #3182CE;
      border-radius: 2px ;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      background: #fff;
      .tt{
        position: relative;
        padding: 18px 40px;
        height: 60px;
        line-height: 24px;
        font-weight: 600;
        &:before{
          content: '';
          position: absolute;
          top: 20px;
          left: 24px;
          display: block;
          width: 8px;
          height: 20px;
          background: #3182CE;
          border-radius: 10px;
        }
      }
      ul{
        margin-bottom: 0;
        padding: 0 24px;
        width: 100%;
        max-height: calc(100% - 60px);
        overflow: auto;
        li{
          position: relative;
          margin-top: 16px;
          padding: 0 8px;
          line-height: 40px;
          background: #F7FAFC;
          .num{
            margin: 0 8px;
            font-weight: 600;
          }
          .font-yellow{
            color: #F9BB1C;
            font-weight: 600;
          }
          &:first-child{
            margin-top: 0;
          }
          .icon-del{
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            font-size: 22px;
            color: #3182CE;
            cursor: pointer;
          }
          &.bg-yellow{
            background: #FFFBE6;
            .icon-del{
              color: #F9BB1C;
            }
          }
        }
      }
    }
    .seat-step-first{
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 16px;
      border-top: 5px solid #3182CE;
      border-radius: 2px ;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      background: #fff;
      .tt{
        position: relative;
        padding: 18px 40px;
        height: 60px;
        line-height: 24px;
        font-weight: 600;
        &:before{
          content: '';
          position: absolute;
          top: 20px;
          left: 24px;
          display: block;
          width: 8px;
          height: 20px;
          background: #3182CE;
          border-radius: 10px;
        }
      }
      .tips{
        padding: 0 24px;
        line-height: 18px;
        font-size: 12px;
        color: #3182CE;
      }
      ul{
        margin-top: 16px;
        margin-bottom: 0;
        padding: 0 24px;
        width: 100%;
        height: calc(100% - 178px);
        overflow: auto;
        li{
          position: relative;
          margin-top: 16px;
          &:first-child{
            margin-top: 0;
          }
          .label{
            margin-bottom: 4px;
            line-height: 22px;
          }
          .form-item{
            position: relative;
            line-height: 36px;
            :deep(.ant-select-show-search){
              width: 100%;
            }
          }
          .icon-del{
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            line-height: 22px;
            font-size: 16px;
            text-decoration:underline;
            color: #1890FF;
            cursor: pointer;
          }
          .error{
            line-height: 18px;
            font-size: 12px;
            .err-info{
              color: #DC5E4F;
            }
            .view{
              margin-left: 6px;
              color: #1890FF;
              border-bottom: 1px solid #1890FF;
              cursor: pointer;
            }
          }
        }
      }
      .btn-box{
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
        width: 100%;
        height: 84px;
        border-top: 1px solid #EBEBEB;
        z-index: 10;
        .font-gray{
          color: #999;
          border: 1px solid #999;
        }
      }
    }
    .seat-step-second{
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 16px;
      border-top: 5px solid #3182CE;
      border-radius: 2px ;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      background: #fff;
      .tt{
        position: relative;
        padding: 18px 40px;
        height: 60px;
        line-height: 24px;
        font-weight: 600;
        &:before{
          content: '';
          position: absolute;
          top: 20px;
          left: 24px;
          display: block;
          width: 8px;
          height: 20px;
          background: #3182CE;
          border-radius: 10px;
        }
      }
      .form-box{
        padding: 0 24px;
        max-height: calc(100% - 144px);
        overflow: auto;
        font-size: 16px;
        .applicant{
          span{
            display: block;
          }
        }
        .rms-form {
          width: 100%;
          margin-top: 16px;
          .rms-form-item{
            position: relative;
            margin: 0;
            width: 100%;
          }
          .flex-width{
            width: 40%;
          }
        }
        :deep(.ant-form-item-label label){
          font-size: 16px;
        }
        :deep(.ant-form-item-control) {
          width: 100%;
        }
        .remind{
          margin: 4px 0 0 0;
          line-height: 18px;
          font-size: 12px;
          color: #DC5E4F;
        }
        .file-List{
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 4px;
          line-height: 24px;
          font-size: 16px;
          .file-name{
            margin-left: 12px;
          }
          :deep(.anticon){
            font-size: 20px;
            color: #3182CE;
          }
          .icon-del{
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
            :deep(.anticon-delete){
              color: #DC5E4F;
            }
            &.disabled{
              :deep(.anticon-delete){
                color: #999 !important;
              }
            }
          }
        }
      }
      .btn-box{
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
        width: 100%;
        height: 84px;
        border-top: 1px solid #EBEBEB;
        z-index: 10;
        .font-gray{
          color: #999;
          border: 1px solid #999;
        }
      }
    }
  }
}
:deep(.seat-info){
  .ant-modal-content{
    .ant-modal-close-x{
      color: #fff;
    }
  }
  .ant-modal-header{
    background: #3182CE;
    .ant-modal-title{
      color: #fff;
    }
  }
  .ant-modal-body{
    padding: 0;
  }
  .seat-info-list{
    margin: 0;
    padding: 0 16px;
    max-height: 354px;
    overflow: auto;
    li{
      position: relative;
      margin-top: 16px;
      padding: 16px 12px;
      font-size: 16px;
      .status{
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        border-radius: 22px 0 0 22px;
        &.bg-green{
          background: #58B99E;
        }
        &.bg-blue{
          background: #1890FF;
        }
      }
      .desc{
        display: flex;
        margin-top: 10px;
        line-height: 22px;
        &:nth-child(2){
          margin-top: 0;
        }
        .label{
          width: 110px;
          color: #4d4d4d;
        }
        .contents{
          i{
            margin-right: 12px;
            color: #1890FF;
          }
        }
      }
      .desc-half{
        display: inline-block;
        min-width: 200px;
        &:nth-child(5){
          .label{
            display: inline-block;
            width: 110px;
          }
          .contents{
            display: inline-block;
            width: 140px;
          }
        }
      }
    }
  }
}
:deep(.seat-repeat-info){
  .ant-modal-body{
    padding: 0;
  }
  .user-person{
    line-height: 40px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  .seat-repeat-info-list{
    margin: 0;
    padding: 0 24px;
    max-height: 240px;
    overflow: auto;
    li{
      position: relative;
      padding: 16px 0;
      font-size: 16px;
      .status{
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        border-radius: 22px 0 0 22px;
        &.bg-green{
          background: #58B99E;
        }
        &.bg-blue{
          background: #1890FF;
        }
      }
      .desc{
        display: flex;
        margin-top: 10px;
        line-height: 22px;
        .label{
          width: 110px;
          color: #4d4d4d;
        }
        .contents{
          i{
            margin-right: 12px;
            color: #1890FF;
          }
        }
      }
      .desc-half{
        display: inline-block;
        margin-right: 20px;
        .label{
          display: inline-block;
          width: 110px;
          color: #4d4d4d;
        }
        .contents{
          i{
            margin-right: 12px;
            color: #1890FF;
          }
        }
      }
    }
  }
}
:deep(.rc-virtual-list-holder){
  max-height: 100px !important;
}
</style>
